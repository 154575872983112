import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'

import { getAll, saveCustomMonster, getCustomMonster, updateCustomMonster } from '../../../apis/monsters';
import { useHistory } from "react-router-dom";
import { handleOnCheckboxChange } from '../../Common/utils';
import TextInput from '../../Common/TextInput';
import NumberInput from '../../Common/NumberInput';

export default function MonsterCreate() {
  const history = useHistory();
  const { monsterId } = useParams()

  const [redirect, setRedirect] = useState(false);
  // page data
  const [data, setData] = useState();
  const [standardSkillsData, setStandardSkillsData] = useState();
  const [professionalSkillsData, setProfessionalSkillsData] = useState();
  const [languagesData, setLanguagesData] = useState();
  const [selectedStandard, setSelectedStandard] = useState();
  const [selectedProfessional, setSelectedProfessional] = useState(0);
  const [selectedProfessionalSpecialization, setSelectedProfessionalSpecialization] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [selectedLanguageSpecialization, setSelectedLanguageSpecialization] = useState(0);
  const [validation, setValidation] = useState({value: false, validationList: []});
  const [loadErrors, setLoadErrors] = useState();
  const [errors, setErrors] = useState();
  const [monster, setMonster] = useState();

  // form variables
  const [name, setName] = useState([{value:'Monster Name', validation: null}]);
  const [type, setType] = useState(1);
  const [combatPower, setCombatPower] = useState([{value: 50, validation: null}]);
  const [race, setRace] = useState(1);
  const [alignment, setAlignment] = useState(1);
  const [habitats, setHabitats] = useState();
  const [ap, setAp] = useState([{value: 3, validation: null}]);
  const [movement, setMovement] = useState([{value: 4, validation: null}]);
  const [initiative, setInitiative] = useState([{value: 15, validation: null}]);
  const [conditions, setConditions] = useState();
  const [size, setSize] = useState([{value: '1x1', validation: null}]);
  const [standardSkills, setStandardSkills] = useState();
  const [professionalSkills, setProfessionalSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [specialEffects, setSpecialEffects] = useState();
  const [description, setDescription] = useState([{value:'description', validation: null}]);
  const [height, setHeight] = useState([{value:'6ft 2in', validation: null}]);
  const [weight, setWeight] = useState([{value:'250lb', validation: null}]);
  const [lifespan, setLifespan] = useState([{value:'90 years', validation: null}]);
  const [abilities, setAbilities] = useState([]);
  const [locations, setLocations] = useState(
    [
      {roll: {value: '01-03', validation: null}, location: {value: 'Right Leg', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
      {roll: {value: '04-06', validation: null}, location: {value: 'Left Leg', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
      {roll: {value: '07-09', validation: null}, location: {value: 'Abdomen', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
      {roll: {value: '10-12', validation: null}, location: {value: 'Chest', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
      {roll: {value: '13-15', validation: null}, location: {value: 'Right Arm', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
      {roll: {value: '16-18', validation: null}, location: {value: 'Left Arm', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
      {roll: {value: '19-20', validation: null}, location: {value: 'Head', validation: null}, ap: {value: 4, validation: null}, hp: {value: 34, validation: null}},
    ]
  );
  //form submit
  const handleSubmit = async (e, saveAsNew=false) => {
    e.preventDefault();
    var valid = checkFormValid();
    if (valid.value) {
      var body = {
        name: name[0].value,
        type: parseInt(type),
        combatPower: parseInt(combatPower[0].value),
        race: parseInt(race),
        alignment: parseInt(alignment),
        actionPoints: parseInt(ap[0].value),
        movement: parseInt(movement[0].value),
        initiative: parseInt(initiative[0].value),
        size: size[0].value,
        locations: locationConversion(),
        description: {
          lifeExpectancy: lifespan[0].value,
          height: height[0].value,
          weight: weight[0].value,
          description: description[0].value,
        },
        abilities: abilityConversion(),
        habitats: boolListToIdList(habitats, data.monsterHabitats),
        conditionImmunities: boolListToIdList(conditions, data.conditions),
        specialEffects: boolListToIdList(specialEffects, data.specialEffects),
        skills: skillResponseList()
      }
      if (monsterId && !saveAsNew) {
        body['id'] = monsterId;
        const response = await updateCustomMonster(body);
        if (response.status === 401 ) {
          history.push('/login');
        } else if (response.status === 200) {
          history.push("/monsters/view");
        } else {
          setErrors(response.body.errorMessage);
        }
      } else {
        const response = await saveCustomMonster(body);
        if (response.status === 401 ) {
          history.push('/login');
        } else if (response.status === 201) {
          history.push("/monsters/view");
        } else {
          setErrors(response.body.errorMessage);
        }
      }
    }
  }

  const checkValid = (value, message, valid) => {
    if (value) {
      valid.value = false;
      valid.validationList.push(message);
    }
    return JSON.parse(JSON.stringify(valid));
  }

  const checkFormValid = () => {
    var valid = {value: true, validationList: []};

    valid = checkValid(name[0].validation, 'Invalid Name.', valid);
    valid = checkValid(combatPower[0].validation, 'Invalid Combat Power.', valid);
    valid = checkValid(ap[0].validation, 'Invalid Action Points.', valid);
    valid = checkValid(movement[0].validation, 'Invalid Movement.', valid);
    valid = checkValid(initiative[0].validation, 'Invalid Initiative.', valid);
    valid = checkValid(size[0].validation, 'Invalid Size.', valid);

    var locBasic = true;
    var locAdvanced = true;
    var dieList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    var locs;
    var locStart;
    var locEnd = null;
    var locIndex;
    locations.forEach((item) => {
      if (item.ap.validation || item.hp.validation || item.location.validation || item.roll.validation) {
        locBasic = false;
      }
      locs = item.roll.value.split('-');
      locStart = parseInt(locs[0]);
      if (locEnd && locStart !== (locEnd + 1)) {
        locAdvanced = false;
      }
      locEnd = parseInt(locs[1]);
      if (locEnd >= locStart) {
        for (let i = locStart; i <= locEnd; i++) {
          locIndex = dieList.indexOf(i);
          if (locIndex === -1) {
            locAdvanced = false;
          } else {
            dieList.splice(locIndex, 1);
          }
        }
      } else {
        locAdvanced = false;
      }
    })
    valid = checkValid(!locAdvanced || dieList.length > 0, 'Location 1d20 ranges need to be in order and cover all numbers 1-20 once each.', valid);
    valid = checkValid(!locBasic, 'One or More Invalid Locations.', valid);
    valid = checkValid(lifespan[0].validation, 'Invalid LifeSpan.', valid);
    valid = checkValid(height[0].validation, 'Invalid Height.', valid);
    valid = checkValid(weight[0].validation, 'Invalid Weight.', valid);
    valid = checkValid(description[0].validation, 'Invalid Description.', valid);

    var abil = true;
    abilities.forEach((item) => {
      if (item.validation) {
        abil = false;
      }
    })
    valid = checkValid(!abil, 'One or More Invalid Abilities.', valid);

    var skill = true;
    standardSkills.forEach((item) => {
      if (item.value.validation) {
        skill = false;
      }
    })
    professionalSkills.forEach((item) => {
      if (item.value.validation) {
        skill = false;
      }
    })
    languages.forEach((item) => {
      if (item.value.validation) {
        skill = false;
      }
    })
    valid = checkValid(!skill, 'One or More Invalid Skills.', valid);
    setValidation(valid);
    return valid;
  } 


  // submit conversions
  const boolListToIdList = (boolList, valueList) => {
    var idList = [];
    boolList.forEach((item, index) => {
        if (item) {
          idList.push(valueList[index].id);
        }
      }
    )
    return idList;
  }

  const skillResponseList = () => {
    var skills = []
    standardSkills.forEach((item) => {
      skills.push({
        skillId: item.id,
        value: parseInt(item.value.value)
      })
    })
    professionalSkills.forEach((item) => {
      if (item.skillSpecializations.length > 0) {
        skills.push({
          skillId: item.id,
          skillSpecializationId: item.skillSpecializations[0].id,
          value: parseInt(item.value.value)
        })
      } else {
        skills.push({
          skillId: item.id,
          value: parseInt(item.value.value)
        })
      }
    })
    languages.forEach((item) => {
      if (item.skillSpecializations.length > 0) {
        skills.push({
          skillId: item.id,
          skillSpecializationId: item.skillSpecializations[0].id,
          value: parseInt(item.value.value)
        })
      } else {
        skills.push({
          skillId: item.id,
          value: parseInt(item.value.value)
        })
      }
    })
    return skills;
  }

  const locationConversion = () => {
    var locationList = [];
    locations.forEach((item) => {
      locationList.push({roll: item.roll.value, location: item.location.value, ap: parseInt(item.ap.value), hp: parseInt(item.hp.value)});
    });
    return locationList;
  }

  const abilityConversion = () => {
    var abilityList = [];
    abilities.forEach((item) => {
      abilityList.push(item.value);
    });
    return abilityList;
  }

  // get data to populate page
  useEffect(() => {
      let mounted = true;
      getAll()
        .then(items => {
          if(mounted) {
            if (items.status === 401) {
              setRedirect(true);
            } else if (items.status === 200) {
              setData(items.body);
              setHabitats(new Array(items.body.monsterHabitats.length).fill(false));
              setConditions(new Array(items.body.conditions.length).fill(false));
              setSpecialEffects(new Array(items.body.specialEffects.length).fill(false));
              
              var data_prof = [];
              var data_standard = [];
              var data_language = [];
              items.body.skills.forEach((item) => {
                item['value'] = {value: 0, validation: null}
                if (item.type.id === 1) {
                  data_standard.push(item);
                } else if (item.type.id === 2) {
                  data_prof.push(item);
                } else if (item.type.id === 3) {
                  data_language.push(item);
                }});
              setStandardSkillsData([]);
              setStandardSkills(data_standard);
              setProfessionalSkillsData(data_prof);
              setLanguagesData(data_language);
              if (monsterId) {
                getCustomMonster(monsterId)
                  .then(response => {
                    if(mounted) {
                      if (response.status === 401) {
                        setRedirect(true);
                      } else if (response.status === 200) {
                        console.log(response.body);
                        setName([{value:response.body.name, validation: null}]);
                        setType(response.body.type.id);
                        setCombatPower([{value: response.body.combatPower, validation: null}]);
                        setRace(response.body.race.id);
                        setAlignment(response.body.alignment.id);
                        setHabitats(items.body.monsterHabitats.map((habitat) => response.body.habitats.some( (monsterHabitat) => monsterHabitat.id === habitat.id) ? true : false));
                        setAp([{value: response.body.actionPoints, validation: null}]);
                        setMovement([{value: response.body.movement, validation: null}]);
                        setInitiative([{value: response.body.initiative, validation: null}]);
                        setConditions(items.body.conditions.map((condition) => response.body.conditionImmunities.some( (monsterCondition) => monsterCondition.id === condition.id) ? true : false));
                        setSize([{value: response.body.size, validation: null}]);
                        var prof = []
                        var standard = []
                        var language = []
                        let skillIndex = 0;
                        let specializationIndex = 0;
                        let tempSkill;
                        response.body.skills.forEach(skill => {
                          if (skill.skillTypeId === 1) {
                            skillIndex = data_standard.findIndex(e => e.id === skill.skillId);
                            if (skillIndex >= 0) {
                              tempSkill = data_standard.splice(skillIndex, 1)[0];
                              tempSkill['value'] = {value: skill.value, validation: null};
                              standard.push(tempSkill);
                            }
                          } else if (skill.skillTypeId === 2) {
                            skillIndex = data_prof.findIndex(e => e.id === skill.skillId);
                            if (skillIndex >= 0) {
                              if (data_prof[skillIndex].skillSpecializations.length > 0) {
                                specializationIndex = data_prof[skillIndex].skillSpecializations.findIndex(e => e.id === skill.skillSpecializationId);
                                if (specializationIndex >= 0) {
                                  tempSkill = JSON.parse(JSON.stringify(data_prof[skillIndex]));
                                  tempSkill.skillSpecializations = [data_prof[skillIndex].skillSpecializations[specializationIndex]];
                                  tempSkill['value'] = {value: skill.value, validation: null};
                                  prof.push(tempSkill);
                                  data_prof[skillIndex].skillSpecializations.splice(specializationIndex, 1);
                                  if (data_prof[skillIndex].skillSpecializations.length === 0) {
                                    data_prof.splice(skillIndex, 1);
                                  }
                                }
                              } else {
                                tempSkill = data_prof.splice(skillIndex, 1)[0];
                                tempSkill['value'] = {value: skill.value, validation: null};
                                prof.push(tempSkill);
                              }
                            }
                          } else if (skill.skillTypeId === 3) {
                            skillIndex = data_language.findIndex(e => e.id === skill.skillId);
                            if (skillIndex >= 0) {
                              if (data_language[skillIndex].skillSpecializations.length > 0) {
                                specializationIndex = data_language[skillIndex].skillSpecializations.findIndex(e => e.id === skill.skillSpecializationId);
                                if (specializationIndex >= 0) {
                                  tempSkill = JSON.parse(JSON.stringify(data_language[skillIndex]));
                                  tempSkill.skillSpecializations = [data_language[skillIndex].skillSpecializations[specializationIndex]];
                                  tempSkill['value'] = {value: skill.value, validation: null};
                                  language.push(tempSkill);
                                  data_language[skillIndex].skillSpecializations.splice(specializationIndex, 1);
                                  if (data_language[skillIndex].skillSpecializations.length === 0) {
                                    data_language.splice(skillIndex, 1);
                                  }
                                }
                              } else {
                                tempSkill = data_language.splice(skillIndex, 1)[0];
                                tempSkill['value'] = {value: skill.value, validation: null};
                                language.push(tempSkill);
                              }
                            }
                          }
                        })
                        setStandardSkillsData(data_standard);
                        setStandardSkills(standard);
                        setProfessionalSkillsData(data_prof);
                        setProfessionalSkills(prof);
                        setLanguagesData(data_language);
                        setLanguages(language);
                        setSpecialEffects(items.body.specialEffects.map((effects) => response.body.specialEffects.some( (monsterspecialEffects) => monsterspecialEffects.id === effects.id) ? true : false));
                        setDescription([{value: response.body.description.description, validation: null}]);
                        setHeight([{value:response.body.description.height, validation: null}]);
                        setWeight([{value:response.body.description.weight, validation: null}]);
                        setLifespan([{value:response.body.description.lifeExpectancy, validation: null}]);
                        setMonster(response.body);
                        let locations = [];
                        response.body.locations.forEach(location => {
                          locations.push({roll: {value: location.roll, validation: null}, location: {value: location.location, validation: null}, ap: {value: location.ap, validation: null}, hp: {value: location.hp, validation: null}});
                        });
                        setLocations(locations);
                        let tempAbility = []
                        response.body.abilities.forEach(ability => {
                          tempAbility.push({value: ability, validation: null});
                        });
                        setAbilities(tempAbility);
                      } else {
                        setLoadErrors(response.body.errorMessage);
                        setData();
                      }
                    }
                  })
              }

            } else {
              setLoadErrors(items.body.errorMessage);
            }
          }
        })
      return () => mounted = false;
    }, [monsterId])

  if (redirect) {
    history.push('/login');
  }

  // utility functions
  const handleOnDictionaryDelete = (position, dictionaryState, dictionaryUpdater, e) => {
    e.preventDefault();
    var tempDictionary = JSON.parse(JSON.stringify(dictionaryState));
    tempDictionary.splice(position, 1);
    dictionaryUpdater(tempDictionary);
  }

  const handleOnLocationAdd = (dictionaryState, dictionaryUpdater, e) => {
    e.preventDefault();
    var tempDictionary = JSON.parse(JSON.stringify(dictionaryState));
    tempDictionary.push({roll: {value: '01-02', validation: null}, location: {value: 'location', validation: null}, ap: {value: 0, validation: null}, hp: {value: 0, validation: null}});
    dictionaryUpdater(tempDictionary);
  }

  const handleOnAbilityAdd = (list, setList, e) => {
    e.preventDefault()
    var tempList = [...list];
    tempList.push({value: '', validation: null});
    setList(tempList);
  }

  const handleOnDictionaryTransfer = (position, e, transferFrom, setTransferFrom, transferTo, setTransferTo) => {
    e.preventDefault();
    transferTo.push(transferFrom.splice(position, 1)[0]);
    setTransferTo([...transferTo]);
    setTransferFrom([...transferFrom]);
  }

  const handleOnProfessionalSkillAdd = (e, transferFrom, setTransferFrom, transferTo, setTransferTo, selectedSkill, selectedSpecializtion, setSelectedSkill, setSelectedSpecialization) => {
    e.preventDefault();
    if (transferFrom[selectedSkill].skillSpecializations.length > 0) {
      let record = JSON.parse(JSON.stringify(transferFrom[selectedSkill]));
      record.skillSpecializations = [transferFrom[selectedSkill].skillSpecializations[selectedSpecializtion]];
      transferTo.push(record);
      transferFrom[selectedSkill].skillSpecializations.splice(selectedSpecializtion, 1);
      if (transferFrom[selectedSkill].skillSpecializations.length === 0) {
        transferFrom.splice(selectedSkill, 1);
      }
    } else {
      transferTo.push(transferFrom.splice(selectedSkill, 1)[0]);
    }
    setSelectedSkill(0);
    setSelectedSpecialization(0);
    setTransferTo([...transferTo]);
    setTransferFrom([...transferFrom]);
  }

  const handleOnProfessionalSkillRemove = (e, transferFrom, setTransferFrom, transferTo, setTransferTo, position, setSelectedSkill, setSelectedSpecialization) => {
    e.preventDefault();
    if (transferFrom[position].skillSpecializations.length > 0) {
      let found = false;
      let loc = 0;
      transferTo.forEach( (item, index) => {
        if (item.id === transferFrom[position].id) {
          loc = index;
          found = true;
        }
      })
      if (found) {
        transferTo[loc].skillSpecializations.push(JSON.parse(JSON.stringify(transferFrom[position].skillSpecializations[0])));
        transferFrom.splice(position, 1);
      } else {
        transferTo.push(JSON.parse(JSON.stringify(transferFrom[position])));
        transferFrom.splice(position, 1);
      }
    } else {
      transferTo.push(JSON.parse(JSON.stringify(transferFrom[position])));
      transferFrom.splice(position, 1);
    }
    setSelectedSkill(0);
    setSelectedSpecialization(0);
    setTransferTo([...transferTo]);
    setTransferFrom([...transferFrom]);
  }


  return(
    <form onSubmit={handleSubmit} noValidate className='no-padding col-12 col-m-12 col-s-12 col-xs-12 flex-container'>
      { (data && (!monsterId || monster) ) ? 
          <div className='flex-row'>
            {monsterId? 
              <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Update A Monster</h2>
            :
              <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Create A Monster</h2>
            }

            <hr></hr>
            <b className='col-12 col-m-12 col-s-12 col-xs-12 text-center fun'>Meta Information</b>
            <div className='flex-row col-9 col-m-9 col-s-9 col-xs-12 no-padding'>
              <div className='flex-column col-4 col-m-4 col-s-6 col-xs-6'>
                Monster Name:
                <TextInput valueList={name} setValueList={setName} validationFormat={'strict'} length={30}/>
                Race:
                <select id="race" name="race" onChange={e => setRace(e.target.value)} defaultValue={race}>
                  {data.monsterRaces.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </select>
                Height:
                <TextInput valueList={height} setValueList={setHeight} validationFormat={'strict'} length={15}/>
                Weight:
                <TextInput valueList={weight} setValueList={setWeight} validationFormat={'strict'} length={15}/>
              </div>
              <div className='flex-column col-4 col-m-4 col-s-6 col-xs-6'>
                {'Type: '} 
                <select id="type" name="type" onChange={e => setType(e.target.value)} defaultValue={type}>
                  {data.monsterTypes.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </select>
                Alignment:
                <select id="alignment" name="alignment" onChange={e => setAlignment(e.target.value)} defaultValue={alignment}>
                  {data.alignments.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </select>
                Life Expectancy:
                <TextInput valueList={lifespan} setValueList={setLifespan} validationFormat={'strict'} length={15}/>
              </div>
              <div className='flex-column col-4 col-m-4 col-s-6 col-xs-6'>
                Combat Power:
                <NumberInput valueList={combatPower} setValueList={setCombatPower} min={1} max={200} />
                Description:
                <TextInput valueList={description} setValueList={setDescription} validationFormat={'nonstrict'} length={1000} type='textarea'/>
              </div>
            </div>
            <div className='flex-column col-3 col-m-3 col-s-3 col-xs-12 no-padding'>
              <div className='flex-column col-12 col-m-12 col-s-12 col-xs-12'>
                Select Habitats:
                <ul className='no-padding no-margin'>
                  {data.monsterHabitats.map((item, index) => 
                    <>
                      <li key={item.id}>
                        <input type="checkbox" id={`habitats-checkbox-${index}`} name={item.name} checked={habitats[index]} onChange={() => handleOnCheckboxChange(index, habitats, setHabitats)}/>
                        <label htmlFor={`habitats-checkbox-${index}`}>{item.name}</label>
                      </li>
                    </>
                  )}
                </ul> 
              </div>
            </div>
            <b className='col-12 col-m-12 col-s-12 col-xs-12 text-center fun'>Attributes</b>
            <div className='flex-column col-4 col-m-4 col-s-4 col-xs-12 no-padding'>
              <div className='flex-column col-12 col-m-12 col-s-12 col-xs-12'>
                Action Points:
                <NumberInput valueList={ap} setValueList={setAp} min={1} max={10}/>
                Movement (Tiles):
                <NumberInput valueList={movement} setValueList={setMovement} min={1} max={10}/>
                Initiative:
                <NumberInput valueList={initiative} setValueList={setInitiative} min={1} max={30}/>
                Size:
                <TextInput valueList={size} setValueList={setSize} validationFormat={'size'}/>
              </div>
            </div>
            <div className='flex-column col-4 col-m-4 col-s-4 col-xs-6 no-padding'>
              <div className='flex-column col-12 col-m-12 col-s-12 col-xs-12'>
                Condition Immunities:
                <ul className='no-padding no-margin'>
                  {data.conditions.map((item, index) => 
                    <>
                      <li key={item.id}>
                        <input type="checkbox" id={`conditions-checkbox-${index}`} name={item.name} checked={conditions[index]} onChange={() => handleOnCheckboxChange(index, conditions, setConditions)}/>
                        <label htmlFor={`conditions-checkbox-${index}`}>{item.name}</label>
                      </li>
                    </>
                  )}
                </ul> 
              </div>
            </div>
            <div className='flex-column col-4 col-m-4 col-s-4 col-xs-6 no-padding'>
              <div className='flex-column col-12 col-m-12 col-s-12 col-xs-12'>
              Usable Special Effects:
              <ul className='no-padding no-margin'>
                {data.specialEffects.map((item, index) => 
                  <>
                    <li key={item.id}>
                      <input type="checkbox" id={`special-effects-checkbox-${index}`} name={item.name} checked={specialEffects[index]} onChange={() => handleOnCheckboxChange(index, specialEffects, setSpecialEffects)}/>
                      <label htmlFor={`special-effects-checkbox-${index}`}>{item.name}</label>
                    </li>
                  </>
                )}
              </ul> 
              </div>
            </div>
            <b className='col-12 col-m-12 col-s-12 col-xs-12 text-center fun'>Locations</b>
            <div className='flex-column col-12 col-m-12 col-s-12 col-xs-12 no-padding'>
              <div className='col-2 col-m-2 no-bottom-padding'>
                <button className='text-btn no-left-margin' onClick={e => handleOnLocationAdd(locations, setLocations, e)} >Add Location</button>
              </div>
              <div className='col-12 col-m-12 col-s-12 col-xs-12 side-scroll site-formatted'>
                <table>
                  <thead>
                    <tr>
                      <th>1d20</th>
                      <th>Location</th>
                      <th>AP</th>
                      <th>HP</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody key={locations.length}>
                    {locations.map((item, index) => 
                      <>
                        <tr key={index}>
                          <td><TextInput position={index} valueList={locations} setValueList={setLocations} validationFormat={'range'} label={''} column={'roll'}/></td>
                          <td><TextInput position={index} valueList={locations} setValueList={setLocations} validationFormat={'strict'} length={20} label={''} column={'location'}/></td>
                          <td><NumberInput position={index} valueList={locations} setValueList={setLocations} min={0} max={20} label={""} column={'ap'}/></td>
                          <td><NumberInput position={index} valueList={locations} setValueList={setLocations} min={1} max={400} label={""} column={'hp'}/></td>
                          <td><button className='x-btn' onClick={e => handleOnDictionaryDelete(index, locations, setLocations, e)} >X</button></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <b className='col-12 col-m-12 col-s-12 col-xs-12 text-center fun'>Skills</b>
            <div className='flex-column col-4 col-m-6 col-s-12 col-xs-12 site-formatted'>
              <b>Standard SKills</b>
              <div>
                {standardSkillsData.length > 0 && (
                  <>
                  <select onChange={e => setSelectedStandard(e.target.value)} key={standardSkillsData.length}>
                    {standardSkillsData.map((item, index) => <option key={index.id} value={index}>{item.name}</option>)}
                  </select>
                  <button className='text-btn-s' onClick={e => handleOnDictionaryTransfer(selectedStandard, e, standardSkillsData, setStandardSkillsData, standardSkills, setStandardSkills)} >Add</button>
                  </>
                )}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Skill</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody key={standardSkills.length}>
                  {standardSkills.map((item, index) => 
                    <>
                      <tr key={index}>
                        <td><NumberInput position={index} valueList={standardSkills} setValueList={setStandardSkills} min={0} max={200} column={'value'}/></td>
                        <td>{item.name}</td>
                        <td><button className='x-btn' onClick={e => handleOnDictionaryTransfer(index, e, standardSkills, setStandardSkills, standardSkillsData, setStandardSkillsData)} >X</button></td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className='flex-column col-4 col-m-6 col-s-12 col-xs-12 site-formatted'>
              <b>Professional SKills</b>
              {professionalSkillsData.length > 0 && (
                <div>
                  <select onChange={e => setSelectedProfessional(e.target.value)} key={professionalSkills.length} defaultValue={selectedProfessional}>
                    {professionalSkillsData.map((item, index) => <option key={index.id} value={index}>{item.name}</option>)}
                  </select>
                  {professionalSkillsData[selectedProfessional].skillSpecializations.length > 0 && (
                    <>
                      <select onChange={e => setSelectedProfessionalSpecialization(e.target.value)} key={professionalSkills.length}>
                        {professionalSkillsData[selectedProfessional].skillSpecializations.map((item, index) => <option key={index.id} value={index}>{item.name}</option>)}
                      </select>
                    </>
                  )}
                  <button className='text-btn-s' onClick={e => handleOnProfessionalSkillAdd(e, professionalSkillsData, setProfessionalSkillsData, professionalSkills, setProfessionalSkills, selectedProfessional, selectedProfessionalSpecialization, setSelectedProfessional, setSelectedProfessionalSpecialization)}>Add</button>
                </div>
              )}
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Skill</th>
                    <th>Specialization</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody key={professionalSkills.length}>
                  {professionalSkills.map((item, index) => 
                    <>
                      <tr key={index}>
                        <td><NumberInput position={index} valueList={professionalSkills} setValueList={setProfessionalSkills} min={0} max={200} column={'value'}/></td>
                        <td>{item.name}</td>
                        <td>
                          { item.skillSpecializations.length === 1 && (
                            <>
                              {item.skillSpecializations[0].name}
                            </>
                          )}
                        </td>
                        <td><button className='x-btn' onClick={e => handleOnProfessionalSkillRemove(e, professionalSkills, setProfessionalSkills, professionalSkillsData, setProfessionalSkillsData, index, setSelectedProfessional, setSelectedProfessionalSpecialization)} >X</button></td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className='flex-column col-4 col-m-6 col-s-12 col-xs-12 site-formatted'>
              <b>Languages</b>
              {languagesData.length > 0 && (
                <div>
                  <select onChange={e => setSelectedLanguage(e.target.value)} key={languages.length} defaultValue={selectedLanguage}>
                    {languagesData.map((item, index) => <option key={index.id} value={index}>{item.name}</option>)}
                  </select>
                  {languagesData[selectedLanguage].skillSpecializations.length > 0 && (
                    <>
                      <select onChange={e => setSelectedLanguageSpecialization(e.target.value)} key={languagesData.length}>
                        {languagesData[selectedLanguage].skillSpecializations.map((item, index) => <option key={index.id} value={index}>{item.name}</option>)}
                      </select>
                    </>
                  )}
                  <button className='text-btn-s' onClick={e => handleOnProfessionalSkillAdd(e, languagesData, setLanguagesData, languages, setLanguages, selectedLanguage, selectedLanguageSpecialization, setSelectedProfessional, setSelectedLanguageSpecialization)}>Add</button>
                </div>
              )}
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Skill</th>
                    <th>Specialization</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody key={languages.length}>
                  {languages.map((item, index) => 
                    <>
                      <tr key={index}>
                        <td><NumberInput position={index} valueList={languages} setValueList={setLanguages} min={0} max={200} label="" column={'value'}/></td>
                        <td>{item.name}</td>
                        <td>
                          { item.skillSpecializations.length === 1 && (
                            <>
                              {item.skillSpecializations[0].name}
                            </>
                          )}
                        </td>
                        <td><button className='x-btn' onClick={e => handleOnProfessionalSkillRemove(e, languages, setLanguages, languagesData, setLanguagesData, index, setSelectedLanguage, setSelectedLanguageSpecialization)} >X</button></td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <b className='col-12 col-m-12 col-s-12 col-xs-12 text-center fun'>Abilities</b>
            <div className='flex-column'>
              <div className='col-2 col-m-2 col-s-2 col-xs-2 no-bottom-padding'>
                <button className='text-btn no-margin'  onClick={e => handleOnAbilityAdd(abilities, setAbilities, e)} >Add Ability</button>
              </div>
              <div className='flex-row col-12 col-m-12 col-s-12 col-xs-12 no-padding'>
                {abilities.map((item, index) => 
                  <>
                    <div className='col-11 col-m-11 col-s-11 col-xs-11 no-bottom-padding'>
                      <TextInput position={index} valueList={abilities} setValueList={setAbilities} validationFormat={'nonstrict'} length={1000} type='textarea'/>
                    </div>
                    <div className='col-1 col-m-1 col-s-1 col-xs-1 no-left-padding no-bottom-padding virt-center'>
                      <button className='x-btn no-left-padding' onClick={e => handleOnDictionaryDelete(index, abilities, setAbilities, e)} >X</button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='flex-row col-12 col-m-12 col-s-12 col-xs-12 center no-padding'>
              <div className='flex-column col-12 col-m-12 col-s-12 col-xs-12 text-center'>
                {validation.validationList.map((item) => 
                  <div>{item}</div>
                )}
                {errors}
              </div>

              {monsterId? 
                <div className='flex-row center no-padding col-12 col-m-12 col-s-12 col-xs-12'>
                  <button className='text-btn' type="submit" onClick={e => handleSubmit(e, false)}>Update Monster</button>
                  <button className='text-btn' type="submit" onClick={e => handleSubmit(e, true)}>Save As New Monster</button>
                </div>
              :
                <div className='flex-column col-1 col-m-2 col-s-2 col-xs-4 center'>
                  <button className='text-btn' type="submit" onClick={e => handleSubmit(e, false)}>Create Monster</button>
                </div>
              }

            </div>
        </div>
      :  
        <>
          {loadErrors}
        </>
      }
    </form>
    
  )
  
}