import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../Common/Button';
import { getSimplified } from '../../../apis/monsters';
import { useHistory } from "react-router-dom";
import { deleteMonster } from '../../../apis/monsters';


export default function MonsterViewAll() {
  const history = useHistory();
  
  const [data, setData] = useState();
  const [redirect, setRedirect] = useState(false);

  const [loadErrors, setLoadErrors] = useState();
  const [errors, setErrors] = useState();

    // get data to populate page
  useEffect(() => {
    let mounted = true;
    getSimplified()
      .then(items => {
        if(mounted) {
          if (items.status === 401) {
            setRedirect(true);
          } else if (items.status === 200){
            setData(items.body);
          } else {
            setLoadErrors(items.body.errorMessage);
          }
        }
      })
    return () => mounted = false;
  }, [])

  if (redirect) {
    history.push('/login');
  }

  const handleOnMonsterDelete = async (id, index) => {
    const items = await deleteMonster(id);
    if (items.status === 401) {
      setRedirect(true);
    } else if (items.status === 200) {
      setErrors();
      var tempData = JSON.parse(JSON.stringify(data));
      tempData.splice(index, 1)
      setData(tempData);
    } else {
      setErrors(items.body.errorMessage);
    }
  }

  return(
    <>
      { data ?
        <div className='flex-container'>
          <div className='flex-row'>
            {errors}
            <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>View All Monsters</h2>
            <hr></hr>
            <div className='col-12 col-m-12 col-s-12 col-xs-12 small-text side-scroll site-formatted'>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Combat Power</th>
                    <th>Race</th>
                    <th>Alignment</th>
                    <th>Habitats</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody key={data.length}>
                  {data.map((item, index) => 
                    <tr>
                      <td>
                        <Button className="text-btn padding-s" as={Link} to={`/monsters/view/${item.id}`}>View</Button>
                        <Button className="text-btn padding-s" as={Link} to={`/monsters/update/${item.id}`}>Edit</Button>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>{item.combatPower}</td>
                      <td>{item.race}</td>
                      <td>{item.alignment}</td>
                      <td>
                        <ul>
                          {item.habitats.map((habitat) => 
                            <li>{habitat}</li>
                          )}
                        </ul>
                      </td>
                      <td className='no-padding'><button className="x-btn" onClick={() => handleOnMonsterDelete(item.id, index)}>x</button></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      :
        <>
          {loadErrors}
        </>
      }
    </>
     
  );
}