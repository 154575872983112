import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'

import { getCustomMonster } from '../../../apis/monsters';
import { useHistory } from "react-router-dom";

export default function CombatLayout() {
  const { monsterId } = useParams()
  const history = useHistory();
  
  const [data, setData] = useState();
  const [redirect, setRedirect] = useState(false);
  const [loadErrors, setLoadErrors] = useState();

  // get data to populate page
  useEffect(() => {
    let mounted = true;
    getCustomMonster(monsterId)
      .then(items => {
        if(mounted) {
          if (items.status === 401) {
            setRedirect(true);
          } else if (items.status === 200) {
            setData(items.body);
          } else {
            setLoadErrors(items.body.errorMessage);
          }
        }
      })
    return () => mounted = false;
  }, [monsterId])

  if (redirect) {
    history.push('/login');
  }


  return (
    <>
      {data ?
        <>
            <div className='flex-container'>
                <div className='flex-column'>
                    <h2 className='padding no-margin page-header'>Monster Viewer</h2>
                    <hr></hr>
                    <div className='padding-left'>
                        <h2 className='no-margin'>{`${data.name} (${data.type.name} - ${data.combatPower}%)`}</h2>
                    </div>
                    <div className='padding-left'>
                        <i>{`${data.race.name}, ${data.alignment.name}, Habitats: (${data.habitats.map( (habitat) => habitat.name).join(', ')})`}</i>
                    </div>
                    <div className='padding'>
                        <h3 className='no-margin'>Attributes</h3>
                    </div>
                    <div className='padding-left book-formatted'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Action Points</th>
                                    <th>Movement</th>
                                    <th>Initiative</th>
                                    <th>Condition Immunities</th>
                                    <th>Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.actionPoints}</td>
                                    <td>{`${data.movement} tiles`}</td>
                                    <td>{`${data.initiative}+1d10`}</td>
                                    <td>{data.conditionImmunities.length > 0? data.conditionImmunities.map( (habitat) => <div>{habitat.name}</div>): 'None'}</td>
                                    <td>{data.size}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='padding'>
                        <h3 className='no-margin'>Locations</h3>
                    </div>
                    <div className='padding-left book-formatted'>
                        <table>
                            <thead>
                                <tr>
                                    <th>1d20</th>
                                    <th>Location</th>
                                    <th>AP/HP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.locations.map((location)=> 
                                    <tr>
                                        <td>{location.roll}</td>
                                        <td>{location.location}</td>
                                        <td>{`${location.ap}/${location.hp}`}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='padding'>
                        <h3 className='no-margin'>Skills</h3>
                    </div>
                    <div className='padding no-bottom-padding'>
                        <b>Languages:</b>
                    </div>
                    <div>
                        <table className='hidden-table'>
                            <tbody>
                                {data.skills.filter(function(skill){return skill.skillTypeId===3}).map( (skill) => 
                                    <tr>
                                        <td className='hidden-table no-virt-padding padding-left'>{`${skill.skill}${skill.skillSpecializationId > 0? '(' + skill.skillSpecialization + ')' : ''}`}</td>
                                        <td className='hidden-table no-virt-padding no-padding'>{`${skill.value}%`}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className='padding no-bottom-padding'>
                        <b>Standard:</b>
                    </div>
                    <div>
                        <table className='hidden-table'>
                            <tbody>
                                {data.skills.filter(function(skill){return skill.skillTypeId===1}).map( (skill) => 
                                    <tr>
                                        <td className='hidden-table no-virt-padding padding-left'>{`${skill.skill}${skill.skillSpecializationId > 0? '(' + skill.skillSpecialization + ')' : ''}`}</td>
                                        <td className='hidden-table no-virt-padding no-padding'>{`${skill.value}%`}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className='padding no-bottom-padding'>
                        <b>Professional:</b>
                    </div>
                    <div>
                        <table className='hidden-table'>
                            <tbody>
                                {data.skills.filter(function(skill){return skill.skillTypeId===2}).map( (skill) => 
                                    <tr>
                                        <td className='hidden-table no-virt-padding padding-left'>{`${skill.skill}${skill.skillSpecializationId > 0? '(' + skill.skillSpecialization + ')' : ''}`}</td>
                                        <td className='hidden-table no-virt-padding no-padding'>{`${skill.value}%`}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className='padding'>
                        <h3 className='no-margin'>Description</h3>
                    </div>
                    <div className='padding-left book-formatted'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Life Expectancy</th>
                                    <th>Height</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.description.lifeExpectancy}</td>
                                    <td>{data.description.height}</td>
                                    <td>{data.description.weight}</td>
                                </tr>
                            </tbody>
                        </table>
                        {data.description.description}
                    </div>
                    <div className='padding'>
                        <h3 className='no-margin'>Abilities</h3>
                    </div>
                    <div className='padding-left'>
                        <b>Special Effects:</b>
                        {data.specialEffects.length > 0? 
                            <>
                                {data.specialEffects.map((effect => 
                                    (effect.name))).join(', ')}
                            </>
                        :
                            <>{` None`}</>
                        }
                    </div>

                    {data.abilities.map( (ability) =>
                        <div className='padding'>
                            {ability}
                        </div>
                    )}
                </div>
            </div>
            {console.log(data)}
        </>
    :
      <div className='flex-container'>
        {loadErrors}
      </div>  
    }
    </>
  );
}