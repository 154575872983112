import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getSimplified, getCustomMonster } from '../../../apis/monsters';
import { handleOnListChange, rollDie, createRoll, handleSkillRoll, handleOnCheckboxChange, getRandomInt } from '../../Common/utils';
import { useHistory } from "react-router-dom";
import NumberInput from '../../Common/NumberInput';
import TextInput from '../../Common/TextInput';

export default function CombatLayout() {
  const history = useHistory();
  
  const [data, setData] = useState();
  const [redirect, setRedirect] = useState(false);

  const [monsterOption, setMonsterOption] = useState();
  const [monsters, setMonsters] = useState([]);
  const [monsterSkillIndex, setMonsterSkillIndex] = useState([]);
  const [monsterInitiative, setMonsterInitiative] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [skillCheck, setSkillCheck] = useState([]);
  const [actionPoints, setActionPoints] = useState([]);
  const [movement, setMovement] = useState([]);
  const [damage, setDamage] = useState([]);
  const [armorIgnoring, setArmorIgnoring] = useState([]);
  const [location, setLocation] = useState([]);
  const [hp, setHp] =  useState([]);
  const [notes, setNotes] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [monsterName, setMonsterName] = useState([]);

  const [errors, setErrors] = useState();
  const [loadErrors, setLoadErrors] = useState();

    // get data to populate page
  useEffect(() => {
    let mounted = true;
    getSimplified()
      .then(items => {
        if(mounted) {
          if (items.status === 401) {
            setRedirect(true);
          } else if (items.status === 200) {
            setData(items.body);
            if (items.body.length > 0) {
              setMonsterOption(items.body[0].id);
            } else {
              setMonsterOption(0);
            }
          } else {
            setLoadErrors(items.body.errorMessage);
          }
        }
      })
    return () => mounted = false;
  }, [])

  if (redirect) {
    history.push('/login');
  }
  const handleOnMonsterAdd = async () => {
    const items = await getCustomMonster(monsterOption);
    if (items.status === 401) {
      setRedirect(true);
    } else if (items.status === 200) {
      setErrors();
      var tempMonsters = JSON.parse(JSON.stringify(monsters));
      tempMonsters.push(items.body);
      setMonsters(JSON.parse(JSON.stringify(tempMonsters)));

      var tempMonsterSkillIndex = [...monsterSkillIndex]
      tempMonsterSkillIndex.push(0);
      setMonsterSkillIndex([...tempMonsterSkillIndex]);
  
      var tempMonsterInitiative = [...monsterInitiative]
      tempMonsterInitiative.push(0);
      setMonsterInitiative([...tempMonsterInitiative]);
  
      var tempDifficult = [...difficulty];
      tempDifficult.push("standard");
      setDifficulty(tempDifficult);
  
      var tempSkillCheck = [...skillCheck];
      tempSkillCheck.push(null);
      setSkillCheck(tempSkillCheck);
  
      var tempActionPoints = [...actionPoints];
      tempActionPoints.push(items.body.actionPoints);
      setActionPoints(tempActionPoints);
  
      var tempMovement = [...movement];
      tempMovement.push(items.body.movement);
      setMovement(tempMovement);
  
      var tempDamage = [...damage];
      tempDamage.push({value: 1, validation: null});
      setDamage(tempDamage);
  
      var tempArmorIgnoring = [...armorIgnoring];
      tempArmorIgnoring.push(false);
      setArmorIgnoring(tempArmorIgnoring);
  
      var tempLocation = [...location];
      tempLocation.push({index: null, location: null});
      setLocation(tempLocation);

      var tempNotes = [...notes];
      tempNotes.push({value: '', validation: null});
      setNotes(tempNotes);

      var tempMonsterName = [...monsterName];
      tempMonsterName.push({value: items.body.name, validation: null});
      setMonsterName(tempMonsterName);

      var tempHp = JSON.parse(JSON.stringify(hp));
      var hpList = []
      items.body.locations.forEach(location => {
        hpList.push(location.hp);
      }); 
      tempHp.push(hpList);
      setHp(tempHp);
    } else {
      setErrors(items.body.errorMessage);
    }
  }

  const handleOnMonsterDelete = (position) => {
     setSelectedIndex(0);

    var tempMonsters = JSON.parse(JSON.stringify(monsters));
    tempMonsters.splice(position, 1);
    setMonsters(JSON.parse(JSON.stringify(tempMonsters)));

    var tempMonsterSkillIndex = [...monsterSkillIndex]
    tempMonsterSkillIndex.splice(position, 1);
    setMonsterSkillIndex([...tempMonsterSkillIndex]);

    var tempMonsterInitiative = [...monsterInitiative]
    tempMonsterInitiative.splice(position, 1);
    setMonsterInitiative([...tempMonsterInitiative]);

    var tempDifficult = [...difficulty];
    tempDifficult.splice(position, 1);
    setDifficulty(tempDifficult);

    var tempSkillCheck = [...skillCheck];
    tempSkillCheck.splice(position, 1);
    setSkillCheck(tempSkillCheck);

    var tempActionPoints = [...actionPoints];
    tempActionPoints.splice(position, 1);
    setActionPoints(tempActionPoints);

    var tempMovement = [...movement];
    tempMovement.splice(position, 1);
    setMovement(tempMovement);

    var tempDamage = [...damage];
    tempDamage.splice(position, 1);
    setDamage(tempDamage);

    var tempArmorIgnoring = [...armorIgnoring];
    tempArmorIgnoring.splice(position, 1);
    setArmorIgnoring(tempArmorIgnoring);

    var tempLocation = [...location];
    tempLocation.splice(position, 1);
    setLocation(tempLocation);

    var tempNotes = [...notes];
    tempNotes.splice(position, 1);
    setNotes(tempNotes);

    var tempMonsterName = [...monsterName];
    tempMonsterName.splice(position, 1);
    setMonsterName(tempMonsterName);

    var tempHp = JSON.parse(JSON.stringify(hp));
    tempHp.splice(position, 1);
    setHp(tempHp);
  }

  const handleRollInitiative = (initiative, position) => {
    var strRoll = createRoll(1, 10, initiative);
    var result = rollDie(strRoll);
    handleOnListChange(position, monsterInitiative, setMonsterInitiative, result);
  }

  const handleCheck = (position) => {
    var result = handleSkillRoll(difficulty[position], monsters[position].skills[monsterSkillIndex[position]].value);
    handleOnListChange(position, skillCheck, setSkillCheck, result);
  }

  const handleSubtract1 = (position, listState, setList) => {
    var list = [...listState];
    list[position] = list[position]-1;
    setList(list);
  }

  const handleDamage = (monsterPosition, locationPosition) => {
    var ap = (armorIgnoring[monsterPosition] ? 0 : monsters[monsterPosition].locations[locationPosition].ap);
    var tempDamage = (parseInt(damage[monsterPosition].value) - ap >= 0 ? parseInt(damage[monsterPosition].value) - ap : 0)
    var tempHp = JSON.parse(JSON.stringify(hp));
    var tempLocationHp = [...tempHp[monsterPosition]];
    tempLocationHp[locationPosition] = (tempLocationHp[locationPosition] -= tempDamage);
    tempHp[monsterPosition] = [...tempLocationHp];
    setHp(JSON.parse(JSON.stringify(tempHp)));
  }

  const handleHeal = (monsterPosition, locationPosition) => {
    var tempHp = JSON.parse(JSON.stringify(hp));
    var tempLocationHp = [...tempHp[monsterPosition]];
    tempLocationHp[locationPosition] = parseInt(tempLocationHp[locationPosition] += parseInt(damage[monsterPosition].value));
    tempHp[monsterPosition] = [...tempLocationHp];
    setHp(JSON.parse(JSON.stringify(tempHp)));
  }

  const handleReset = (monsterPosition, locationPosition) => {
    var tempHp = JSON.parse(JSON.stringify(hp));
    var tempLocationHp = [...tempHp[monsterPosition]];
    tempLocationHp[locationPosition] = monsters[monsterPosition].locations[locationPosition].hp;
    tempHp[monsterPosition] = [...tempLocationHp];
    setHp(JSON.parse(JSON.stringify(tempHp)));
  }

  function handleRollLocation(monsterPosition) {
    var result = {index: null, location: null};
    var tempLocation = [...location];
    var roll = getRandomInt(20);
    var bounds;
    var lower;
    var upper;
    var found = false;
    monsters[monsterPosition].locations.forEach((location, index) => {
      bounds = location.roll.split('-');
      lower = bounds[0];
      upper = bounds[1];
      if (roll >= lower && roll <= upper && !found) {
        result.index = index;
        result.location = location.location;
        found = true;
      }
    });
    tempLocation[monsterPosition] = result;
    setLocation([...tempLocation]);
    return result;
  }

  const handleApplyDamageToRandomLocation = (monsterPosition) => {
    var result = handleRollLocation(monsterPosition);
    handleDamage(monsterPosition, result.index);
  }

  return (
    <>
      {data ?
        <>
          <div className="flex-container">
            <div className="flex-row">
              <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Combat Layout</h2>
              <hr></hr>
              <div className='col-12 col-m-12 col-s-12 col-xs-12'>
                {data.length === 0 ? 
                  <>
                    <Link className='link' to="/monsters/create">Please Create A Monster</Link>
                  </>
                : 
                  <>
                    <div className='no-padding col-12'>
                      {errors}
                    </div>
                    {`Select Monster: `}
                    <select id="monsterOption" name="monsterOption" onChange={(e) => setMonsterOption(e.target.value)} defaultValue={monsterOption}>
                      {data.map((item) => (
                        <option key={item.id} value={item.id}>{`${item.name}(${item.combatPower})`}</option>
                      ))}
                    </select>
                    <button className="text-btn-s" onClick={handleOnMonsterAdd}>Add</button>
                  </>
                }
              </div>
            </div>
            <div key={monsters.length} className='no-padding col-12 col-m-12 col-s-12 col-xs-12'>
              <div className='no-bottom-padding col-12 col-m-12 col-s-12 col-xs-12'>
              {monsters.length > 0 &&
                <div className='col-12 col-m-12 col-s-12 col-xs-12 border no-bottom-padding no-left-padding'>
                  {monsterName.map((monster, monsterIndex) => (
                    <button className={`${selectedIndex === monsterIndex ? 'active-tab': 'tab'}`} onClick={() => setSelectedIndex(monsterIndex)}>{monster.value}</button>
                  ))}
                </div>
              }
              </div>
              <div className='padding'>
              {monsters.map((monster, monsterIndex) => (
                  <div className={`flex-row border ${!(selectedIndex === monsterIndex) && 'hidden'}`}>
                    <div className="col-12 col-m-12 col-s-12 col-xs-12">
                    <table className='hidden-table no-padding'>
                        <tbody>
                          <tr>
                            <td className='hidden-table no-padding'>
                              <TextInput position={monsterIndex} valueList={monsterName} setValueList={setMonsterName} validationFormat={'strict'} length={30}/>
                            </td>
                            <td className='hidden-table no-padding'><button className="x-btn" onClick={() => handleOnMonsterDelete(monsterIndex)}>x</button></td>
                          </tr>  
                        </tbody>
                      </table>
                    </div>
                    <div className='col-3 col-m-6 col-s-6 col-xs-6'>
                      <div className='flex-column'>
                        <div>
                          {`Select Skill: `}
                          <select name="skillDropdown" onChange={(e) => handleOnListChange(monsterIndex, monsterSkillIndex, setMonsterSkillIndex, e.target.value)} defaultValue={monsterSkillIndex[monsterIndex]}>
                            {monster.skills.map((skill, skillIndex) => (
                              <option value={skillIndex}>
                                {skill.skillSpecializationId > 0 ? (
                                  <>
                                    {`${skill.skill}(${skill.skillSpecialization})-${skill.value}`}
                                  </>
                                ) : (
                                  <>{`${skill.skill}-${skill.value}`}</>
                                )}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          {`Choose Roll Difficulty: `}
                          <select id="difficulty" name="difficulty" onChange={(e) => handleOnListChange( monsterIndex, difficulty, setDifficulty, e.target.value)} defaultValue={difficulty[monsterIndex]}>
                            <option value="veryeasy">Very Easy</option>
                            <option value="easy">Easy</option>
                            <option value="standard">Standard</option>
                            <option value="hard">Hard</option>
                            <option value="formidable">Formidable</option>
                            <option value="herculean">Herculean</option>
                          </select>
                        </div>
                        <div>
                          <button className='text-btn no-left-margin' onClick={() => handleCheck(monsterIndex)}>Roll Skill Check</button>
                        </div>
                      </div>
                    </div>
                      <div className='col-4 col-m-6 col-s-6 col-xs-6'>
                        {skillCheck[monsterIndex] && (
                          <>
                            {`You Rolled: ${skillCheck[monsterIndex].roll} With a Target Value of ${skillCheck[monsterIndex].modifiedSkillValue}`}
                            <br></br>
                            {`You Check: ${skillCheck[monsterIndex].result}`}
                            <br></br>
                            {skillCheck[monsterIndex].reason}
                          </>
                        )}
                      </div>
                      <div className='col-3 col-m-4 col-s-6 col-xs-12 flex-column'>
                        <div>
                          {`Action Points: `}
                          {`${actionPoints[monsterIndex]} `}
                          <button className='text-btn-s no-left-margin' onClick={() => handleSubtract1(monsterIndex, actionPoints, setActionPoints)}>-1</button>
                          <button className='text-btn-s no-left-margin' onClick={() => handleOnListChange( monsterIndex, actionPoints, setActionPoints, monster.actionPoints)}>Reset</button>
                        </div>
                        <div>
                          {`Movement: `}
                          {`${movement[monsterIndex]} `}
                          <button className='text-btn-s no-left-margin' onClick={() => handleSubtract1(monsterIndex, movement, setMovement)}>-1</button>
                          <button className='text-btn-s no-left-margin' onClick={() => handleOnListChange( monsterIndex, movement, setMovement, monster.movement)}>Reset</button>
                        </div>
                        <div>
                          <button className='text-btn no-left-margin' onClick={(e) => handleRollInitiative(monster.initiative, monsterIndex)}>{`Roll Initiative: ${monsterInitiative[monsterIndex]}`}</button>
                        </div>
                        <div>
                          {`Size: `}
                          {monster.size}
                        </div>
                      </div>
                      <div className='col-2 col-m-3 col-s-6 col-xs-12'>
                        <b>Condition Immunities:</b>
                        {monster.conditionImmunities.map((condition) => (
                          <div>
                            {condition.name}
                          </div>
                        ))}
                        <br></br>
                        <b>Special Effects:</b>
                        {monster.specialEffects.map((specialEffect) => (
                          <div>
                            {specialEffect.name}
                          </div>
                        ))}
                      </div>
                      <div className='col-7 col-m-12 col-s-12 col-xs-12'>
                        <div className='flex-column'>
                          <div>
                            <div className='flex-row no-padding'>
                              <div className='col-4 col-m-4 col-s-4 col-xs-12 no-padding'>
                                <NumberInput position={monsterIndex} valueList={damage} setValueList={setDamage} min={0} max={200} label=" Enter Damage: " />
                              </div>
                              <div className='col-8 col-m-8 col-s-8 col-xs-12 no-padding'>
                                {` Armor Ignoring Damage? `}
                                <input type="checkbox" checked={armorIgnoring[monsterIndex]} onChange={() => handleOnCheckboxChange(monsterIndex, armorIgnoring, setArmorIgnoring)}/>
                              </div>
                            </div>
                          </div>
                          <div className='no-padding col-7 col-m-12 col-s-12 col-xs-12'>
                            <button className='text-btn no-left-margin' onClick={() => handleApplyDamageToRandomLocation(monsterIndex)}>Apply Damage To Random Location</button>
                            <button className='text-btn no-left-margin' onClick={() => handleRollLocation(monsterIndex)}>Roll Location</button>
                          </div>
                          <div>
                            {location[monsterIndex].location ? 
                              <>{`Rolled Location: ${location[monsterIndex].location}`}</> :
                              <>{`Rolled Location: `}</>}
                          </div>
                          <div className='small-text side-scroll site-formatted'>
                            <table>
                              <thead>
                                <tr>
                                  <th>Roll</th>
                                  <th>Location</th>
                                  <th>Current / Max HP</th>
                                  <th>AP</th>
                                  <th>Wounds</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {monster.locations.map((location, locationIndex) => (
                                  <tr>
                                    <td>{location.roll}</td>
                                    <td>{location.location}</td>
                                    <td>{`${hp[monsterIndex][locationIndex]}/${location.hp}`}</td>
                                    <td>{location.ap}</td>
                                    <td>{monsters[monsterIndex].type.name === "Normal" ?
                                        <>{`N/A`}</>
                                      :
                                        <>{hp[monsterIndex][locationIndex] >= location.hp ?
                                          <>{``}</>  
                                        :
                                          <>{hp[monsterIndex][locationIndex] > Math.ceil(location.hp / 2) ?
                                            <>{`Minor`}</>  
                                          :
                                            <>{hp[monsterIndex][locationIndex] > 0 ?
                                              <>{`Serious`}</>  
                                            :
                                              <>{`Critical`}</>
                                            }</>
                                          }</>
                                        }</>
                                      }
                                    </td>
                                    <td>
                                      <button className='text-btn no-left-margin' onClick={() => handleDamage(monsterIndex, locationIndex)}>Damage</button>
                                      <button className='text-btn no-left-margin' onClick={() => handleHeal(monsterIndex, locationIndex)}>Heal</button>
                                      <button className='text-btn no-left-margin' onClick={() => handleReset(monsterIndex, locationIndex)}>Reset</button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='col-5 col-m-12 col-s-12 col-xs-12'>
                        <TextInput position={monsterIndex} valueList={notes} setValueList={setNotes} type='textarea'/>
                      </div>
                    <div className='col-12 col-m-12 col-s-12 col-xs-12 flex-column'>
                      <b>Abilities</b>
                      {monster.abilities.map((ability) => (
                        <>
                          <div className='col-12 col-m-12 col-s-12 col-xs-12'>
                            {ability}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
                </div>
            </div>
          </div>
        </>
    :
      <div className='flex-container'>
        {loadErrors}
      </div>  
    }
    </>
  );
}